
import React, { useEffect, useState } from "react";

import { useIntl } from "gatsby-plugin-intl";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CvcConfirmActionDialog from "ui/CvcConfirmActionDialog";
import CvcListVirtualized from "ui/CvcListVirtualized";
import { RhfAutocompleteWithCreateOptionDialog } from "ui/RhfAutocompleteWithAdd";

const StandardizeDestinationPanel = ({ 
    disabledValidateBtn, 
    selectedReferenceTable, 
    selectedData, 
    onSelectDestination, 
    onValidate, 
    onCancel 
}) => {
    
    const intl = useIntl();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);   

    const defaultValue = { 
        destination: {
            id: null, 
            label: '',
            listCode: null,
            langCode: null,
            count: null
        }
    };

    const selectedDataMapped = selectedData.map(data => {
        return {...data, 'label': data?.label?.replace(/\s*\(.*?\)\s*/g, '')};
    }); 

    const validateDestination = yup.object().shape({
        destination: yup.object().nullable(true)
        .shape({
            label: yup
            .string()
            .test('empty', 'error.field.required.and.not.empty', (value) => {
                return value.trim() != "" ? true : false;
            })
        })    
    });

    const useFormMethods = useForm({
        resolver: yupResolver(validateDestination),
        defaultValues: defaultValue,
        mode: "onChange",
    });

    const {
        control, getValues,
        formState: { errors},
      } = useFormMethods;

    const handleGetOptionLabel = (option) => {
        const hasLabel = Object.hasOwn(option, 'label');
        return hasLabel ? option.label : '';
    };

    const submitForm = async () => {
        const isValid = await useFormMethods.trigger();          
        if (isValid) {
            useFormMethods.handleSubmit(onValidate)();
        }
    };

    const handleClickValidateStandardization = async () => {
        setOpenConfirmDialog(true);
    }

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const handleConfirmAction = () => {
        setOpenConfirmDialog(false);
        submitForm();
    }

    const handleInputUserOption = (inputValue) => { 
        return { listCode: selectedReferenceTable.value, label: inputValue };
    }

    const RightFormPanel = () => {
        return (
            <Grid container direction='column' sx={{ rowGap: 2, width: '100%', height: '100%' }}
                    justifyContent={'start'} justifyItems={'start'}
                    alignContent={'start'} alignItems={'start'}
                >
                <Grid item alignContent={'center'}>
                    <Typography component="p">
                        {intl.formatMessage({ id: "backoffice.repositories.standardisation.autocomplete.destination" })}
                    </Typography>
                </Grid>
                <Grid item>                                                            
                    <RhfAutocompleteWithCreateOptionDialog                            
                        name="destination"
                        label={intl.formatMessage({ id: "backoffice.repositories.standardisation.autocomplete.destination" })}
                        options={selectedDataMapped}
                        control={control}
                        error={errors && errors.destination ? errors.destination.label : null}
                        onOptionChange={onSelectDestination}
                        getOptionLabel={handleGetOptionLabel}
                        getInputUserOption={handleInputUserOption}                                               
                        sx={{ width: 200 }}                                                         
                    /> 
                </Grid> 
                <Grid container gap={2}>
                    <Grid item>
                        <Button variant='contained' disabled={disabledValidateBtn} onClick={handleClickValidateStandardization}>
                            {intl.formatMessage({ id: "backoffice.repositories.standardisation.btn.validate" })}
                        </Button>
                        <CvcConfirmActionDialog 
                            open={openConfirmDialog} 
                            title={intl.formatMessage({id: "backoffice.repositories.standardisation.dialog.confirmReplace.title"})} 
                            contentText={intl.formatMessage({id: "backoffice.repositories.standardisation.dialog.confirmReplace.contentText"}, {selectedValue: getValues("destination")?.label})} 
                            onClose={handleCloseConfirmDialog} 
                            cancelBtnLabel={intl.formatMessage({id: "backoffice.repositories.standardisation.dialog.confirmReplace.btn.cancel"})} 
                            onCancel={handleCloseConfirmDialog} 
                            actionBtnLabel={intl.formatMessage({id: "backoffice.repositories.standardisation.dialog.confirmReplace.btn.replace"})} 
                            onAction={handleConfirmAction}  
                        />                        
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' onClick={onCancel}>
                            {intl.formatMessage({ id: "backoffice.repositories.standardisation.btn.cancel" })}
                        </Button>
                    </Grid>         
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container gap={2}
            sx={{ width: '100%', height: '100%', marginTop: '20px' }}
        >                
            <Grid item xs={5}>
                <Typography component="p">
                    {intl.formatMessage({ id: "backoffice.repositories.standardisation.list.selectedData" })}
                </Typography>
                <CvcListVirtualized 
                    items={selectedData} 
                />
            </Grid>
            <Grid item xs={6} width={"100%"} height={"100%"}>
                <RightFormPanel />
            </Grid>
        </Grid>
    )
}

export default StandardizeDestinationPanel;