import React from "react";
import { useState } from "react";

import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button/Button";
import { useIntl } from "gatsby-plugin-intl";

import CvcLoadingProgress from "ui/CvcLoadingProgress";
import repositoryService from "services/api/repositoryService";
import { handleError } from "utils/error/error-utils";
import CvcConfirmActionDialog from "ui/CvcConfirmActionDialog";
import { downloadCSV, convertToCSV } from "utils/export-utils";



const ExportReferenceDatasTab = ({ 
    selectedReferenceTable, 
    showErrorMessage, 
    showSuccessMessage 
}) => {

    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [disabledExport, setDisabledExport] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleClickExport = async () => {
        setOpenConfirmDialog(true);
    }

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const handleConfirmAction = () => {
        setOpenConfirmDialog(false);
        handleExportReferencesDatas();
    }

    const handleExportReferencesDatas = async () => {
        setDisabledExport(true);
        setLoading(true);        
        try {
            const exportReferenceDataDto = {
                referenceTableCode: selectedReferenceTable.value
            }  
            const response = await repositoryService.exportReferenceData(exportReferenceDataDto); 
            const data = await convertToCSV(response.data);                                                      
            await downloadCSV(data, `${selectedReferenceTable.label}`);
            showSuccessMessage(intl.formatMessage({ id: 'backoffice.repositories.export.export.success'}));            
            setLoading(false);
            setDisabledExport(false);
        } catch(error) {
            handleError(
                intl, error, 
                intl.formatMessage({ id: 'backoffice.repositories.export.export.error'}), 
                showErrorMessage
            );
            setLoading(false);
            setDisabledExport(false);
        };
    };

    return (<>
        <Grid container flexDirection='column' alignItems='start' marginTop={2} rowSpacing={2} sx={{ width: '100%', height: '100%' }}> 
            <Grid item>
                <Typography component="p">
                    {intl.formatMessage({ id: "backoffice.repositories.export.title" })}
                </Typography>
            </Grid>               
            <Grid item>
                <Button 
                    variant='contained' 
                    disabled={disabledExport}
                    onClick={handleClickExport}
                >
                    {intl.formatMessage({ id: "backoffice.repositories.export.btn.export" })}
                </Button>
                <CvcConfirmActionDialog 
                    open={openConfirmDialog} 
                    title={intl.formatMessage({id: "backoffice.repositories.export.dialog.confirmExport.title"})} 
                    contentText={intl.formatMessage({id: "backoffice.repositories.export.dialog.confirmExport.contentText"})} 
                    onClose={handleCloseConfirmDialog} 
                    cancelBtnLabel={intl.formatMessage({id: "backoffice.repositories.export.dialog.confirmExport.btn.cancel"})} 
                    onCancel={handleCloseConfirmDialog} 
                    actionBtnLabel={intl.formatMessage({id: "backoffice.repositories.export.dialog.confirmExport.btn.export"})} 
                    onAction={handleConfirmAction}  
                />
            </Grid>  
            {loading ? <CvcLoadingProgress/> : <></>}       
        </Grid>
    </>);
}


export default ExportReferenceDatasTab;