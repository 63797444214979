import React, { useEffect, useState } from "react";

import { Grid, Tab, Tabs } from "@mui/material";
import { useIntl } from "gatsby-plugin-intl";

import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";
import CvcTabPanel from "ui/CvcTabPanel";
import CvcAutoComplete from "ui/CvcAutoComplete";

import listOfValueService from "services/api/listOfValueService";
import { handleError } from "utils/error/error-utils";

import AddReferenceDataTab from "components/backoffice/repositories/tabs/AddReferenceDataTab";
import DeleteReferenceDataTab from "components/backoffice/repositories/tabs/DeleteReferenceDataTab";
import StandardizeReferenceDataTab from "components/backoffice/repositories/tabs/StandardizeReferenceDataTab";
import ExportReferenceDataTab from "components/backoffice/repositories/tabs/ExportReferenceDataTab";


const RepositoriesPage = () => {
    const intl = useIntl();   
    const [activeTab, setActiveTab] = useState("");

    const [loadingTables, setLoadingTables] = useState(false);
    const [disabledTables, setDisabledTables] = useState(false);
    const [referenceTableOptions, setReferenceTableOptions] = useState([]);
    const [selectedReferenceTable, setSelectedReferenceTable] = useState(null);

    
    const [resultMessage, setResultMessage] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
  
    const tabs  = [    
        { value: "standardisation", title: "backoffice.repositories.tabs.cardHeader.standardisation" },
        { value: "creation", title: "backoffice.repositories.tabs.cardHeader.creation" },
        { value: "deletion", title: "backoffice.repositories.tabs.cardHeader.deletion" },
        { value: "export", title: "backoffice.repositories.tabs.cardHeader.export" }
    ]; 

    const sortByLabel = (item1, item2) => {
        if (item1.label < item2.label) {
            return -1;
        }
        if (item1.label > item2.label) {
            return 1;
        }
        return 0;
    }   
        
    const buildReferenceTablesOptions =  (repositories) => {                 
        let tables = repositories.map(repository => 
            { return { value: repository, label: intl.formatMessage({ id: `backoffice.repositories.reference.tables.${repository}`})}
        });    
        return tables.sort(sortByLabel);  
    };

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoadingTables(true);
            try {
                const result =await listOfValueService.findReferenceTables();
                if(mounted){                    
                    setReferenceTableOptions(buildReferenceTablesOptions(result.data));                                                      
                }                                
            } catch (error) {
                handleError(intl, error, "error fetching references tables ", showErrorMessage);              
            } finally {
                if(mounted) setLoadingTables(false);
            }
        };
        if(!loadingTables) fetchData();        
        return () => { mounted=false; };
    }, []);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);        
    };

    const handleReferenceTableChange = (newValue) => {
        if(newValue){
            if(!activeTab){
                setActiveTab("standardisation");
            }            
        } else {
            setActiveTab("");
        }        
    }

    const showErrorMessage = (errorMessage) => {
        setResultMessage(errorMessage);
        setOpenError(true);
    }

    const showSuccessMessage = (successMessage) => {
        setResultMessage(successMessage);
        setOpenSuccess(true);
    }

    return (<>
        <LayoutBo>

            <ResultSnackbars
                messageStr={resultMessage}
                openError={openError}
                setOpenError={setOpenError}
                openSuccess={openSuccess}                
                setOpenSuccess={setOpenSuccess}                
            />  
            
            <Grid container sx={{ height: '100%', bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
                
                <Grid item xs={12} justifyItems={"center"} sx={{ marginY: "20px"}}>
                    <CvcAutoComplete 
                        id="select-reference-table"
                        label={"backoffice.repositories.selectReferenceTable.label"}
                        value={selectedReferenceTable}
                        options={referenceTableOptions}
                        loading={loadingTables}
                        disabled={disabledTables}
                        onChange={handleReferenceTableChange}
                        sx={{ width: "400px" }} 
                        setValue={setSelectedReferenceTable}                    
                    />
                </Grid>

                <Grid item xs={12}>
                    { selectedReferenceTable && 
                        <Tabs 
                            value={activeTab} 
                            onChange={handleTabChange} 
                            aria-label="user edit" 
                            variant="scrollable"
                            sx={{ height: '100%' }}
                        >
                            { tabs.map((tab, index) => 
                                <Tab key={index} label={intl.formatMessage({id: tab.title})}  value={tab.value} />
                            )}                        
                        </Tabs>
                    }
                </Grid>

            </Grid>

            { selectedReferenceTable && <>
                <CvcTabPanel id="standardisation" index="standardisation" value={activeTab} ariaLabel="standardisation">
                    <StandardizeReferenceDataTab 
                        selectedReferenceTable= {selectedReferenceTable} 
                        setDisabledTables={setDisabledTables}
                        showErrorMessage={showErrorMessage} 
                        showSuccessMessage={showSuccessMessage} 
                    />
                </CvcTabPanel>            
                <CvcTabPanel id="creation" index="creation" value={activeTab} ariaLabel="creation">
                    <AddReferenceDataTab 
                        selectedReferenceTable= {selectedReferenceTable} 
                        setDisabledTables={setDisabledTables}
                        showErrorMessage={showErrorMessage} 
                        showSuccessMessage={showSuccessMessage}
                    />
                </CvcTabPanel>
                <CvcTabPanel id="deletion" index="deletion" value={activeTab} ariaLabel="deletion">
                    <DeleteReferenceDataTab 
                        selectedReferenceTable= {selectedReferenceTable} 
                        setDisabledTables={setDisabledTables}
                        showErrorMessage={showErrorMessage} 
                        showSuccessMessage={showSuccessMessage}
                    />
                </CvcTabPanel>
                <CvcTabPanel id="export" index="export" value={activeTab} ariaLabel="export">
                    <ExportReferenceDataTab 
                        selectedReferenceTable= {selectedReferenceTable} 
                        showErrorMessage={showErrorMessage} 
                        showSuccessMessage={showSuccessMessage}
                    />
                </CvcTabPanel>
                </>
            }
                    
        </LayoutBo>     
    </>);
}

export default RepositoriesPage;