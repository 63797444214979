import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useIntl } from "gatsby-plugin-intl";
import CvcTransferList from "ui/CvcTransferList";


const StandardizeSelectDataPanel = ({ 
    referenceDataList, 
    selectedData, 
    onChange, 
    onReloadData, 
    onStandardize, 
    disabledStandardizeBtn 
}) => {
    const intl = useIntl();
    const showActions = {
        showReloadData: true,
        showMoveAllRight: false,
        showMoveAllLeft: true,
        showMoveSelectedRight: true,
        showMoveSelectedLeft: true
    }
    return (
        <Grid container alignItems='center' 
                sx={{ width: '100%', height: '100%' }}
        >                
            <Grid item width='calc(100% - 140px)'>

                <Grid container marginTop={'20px'}>
                    <Grid item width={'calc((100% - 102px)/2 + 102px)'}>
                        <Typography component="p">
                            {intl.formatMessage({ id: "backoffice.repositories.standardisation.transferlist.left.title" })}
                        </Typography>
                    </Grid>
                    <Grid item width={'calc((100% - 102px)/2'}>
                        <Typography component="p">
                            {intl.formatMessage({ id: "backoffice.repositories.standardisation.transferlist.right.title" })}
                        </Typography>
                    </Grid>
                </Grid> 

                <CvcTransferList 
                    filterLabel={intl.formatMessage({ id: "backoffice.repositories.standardisation.transferlist.filter.label" })}
                    showActions={showActions}
                    items={referenceDataList}
                    selectedItems={selectedData}
                    onChange={onChange}
                    showReloadData={true}
                    onReloadData={onReloadData}                
                    listHeight={440}
                />
            </Grid>

            <Grid item width='140px'>
                <Button variant='contained' disabled={disabledStandardizeBtn} onClick={onStandardize}>
                    {intl.formatMessage({ id: "backoffice.repositories.standardisation.btn.standardise" })}
                </Button>
            </Grid>
        </Grid>
    )
}

export default StandardizeSelectDataPanel;