import * as React from 'react';

import { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useFormContext } from 'react-hook-form';
import { Controller } from "react-hook-form";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';


const defaultSx = { width: 300 };
const defaultDialog = {
  title: 'autocompleteDialog.dialogTitle',
  contentText: 'autocompleteDialog.dialogContentText',
  cancelBtnLabel: 'autocompleteDialog.dialogActionCancel',
  addBtnLabel: 'autocompleteDialog.dialogActionAdd',
  textfieldLabel: 'autocompleteDialog.dialogTextFieldLabel',
}

const filter = createFilterOptions();

const CvcAutocompleteWithCreateOptionDialog = ({   
    label, 
    options, 
    getInputUserOption, 
    getOptionLabel, 
    value, 
    onBlur,
    onChange, 
    error,
    sx = { width: 300 } 
}) =>
{
    const intl = useIntl();
    const [internValue, setInternValue] = useState(null);
    const handleIsOptionEqualToValue = (option, value) => {
      return option.id === value.id
    }; 

    return (
        <Autocomplete
            
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys

            sx={sx}            
            options={options}
            isOptionEqualToValue={handleIsOptionEqualToValue}

            value={value}
            onBlur={onBlur}
            onChange={(event, newValue) => {
                let newInternValue = null;
                if (typeof newValue === 'string') {
                    newInternValue = { label: newValue };
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    newInternValue = getInputUserOption(newValue.inputValue);
                } else {
                    newInternValue = newValue;
                }  
                setInternValue(newInternValue);
                //console.log('CvcAutocompleteWithCreateOptionDialog - onChange - newInternValue: ', newInternValue);
                onChange(newInternValue);
            }}

            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === getOptionLabel(option));
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        label: `Add "${inputValue}"`,
                    });
                }
                return filtered;
            }}

            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return getOptionLabel(option);
            }}

            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        {getOptionLabel(option)}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField {...params} 
                  variant='standard' 
                  label={label} 
                  error={!!error}
                  helperText={ error ? intl.formatMessage({id: error.message}) : null }          
                />
            )}            
        />
    );
}

const RhfAutocompleteWithCreateOptionDialog = ({ 
  id = 'CvcAutocompleteWithCreateOptionDialog', 
  name, 
  label, 
  control,
  onOptionChange = null,
  getOptionLabel,
  getInputUserOption = null,
  defaultValue = null,
  placeholder = "", 
  options = [], 
  required = false, 
  dialog = defaultDialog, 
  error = null, 
  sx = defaultSx 
}) => {
  
 

  return (
    <React.Fragment>
      <Controller
        name={name}
        control={control}                       
        render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
          <>
            <CvcAutocompleteWithCreateOptionDialog
                label={label}
                sx={{ width: 300 }}
                options={options}
                value={value || []}                
                onBlur={onBlur}
                onChange={(selectedOption) => {
                  onOptionChange(selectedOption);
                  onChange(selectedOption);
                }}
                getOptionLabel={getOptionLabel}
                getInputUserOption={getInputUserOption} 
                error={error}                   
            /> 
          </>
        )}
      />
    </React.Fragment>
  );
}


export {
  CvcAutocompleteWithCreateOptionDialog,
  RhfAutocompleteWithCreateOptionDialog
}

