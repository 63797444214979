import React, { useEffect, useState } from 'react';

import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import Calendar from 'react-calendar';
import moment from 'moment';

import UpcomingSessionList from 'components/catalog/UpcomingSessionList';
import UserDocumentList from './UserDocumentList';
import { useIntl } from 'gatsby-plugin-intl';
import themeMuiV5 from 'components/common/ThemeMuiV5';
import NewsDetail from 'components/news/NewsDetail';


const checkAvailableSessions = (sessions) => {
    return sessions
        .filter(
            session => {
                let sessionEndTime = moment(new Date(session.endTime))
                return sessionEndTime.isAfter(new Date());
            }).length > 0;
};  
  
const SidePanel = ({ courses, sessions, onSessionClick, userId=null,  news=null }) => {
  
    const intl = useIntl();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const hasSessions = sessions?.length > 0 && checkAvailableSessions(sessions);
    const [loading, setLoading] = useState(false);
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [calendarSessionDays, setCalendarSessionDays] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState(sessions); 
    const filterSessions = (date) => {
    const upcomingSessions = sessions?.filter(
                session => {
                    let sessionEndTime = moment(new Date(session.endTime))
                    let eventDate = moment(date);
                    return sessionEndTime.isAfter(eventDate);
                });
        setFilteredSessions(upcomingSessions);
    }
  
    const stylesSidePanel = {        
        
        gridLeftItem: {
            display: 'flex'
        },
        gridRightItem: {
            display: 'flex',
            flexDirection: 'column'
        },           
        divCalendar: {
            display: 'flex',                
            justifyContent: 'center',                        
            marginTop:  isMobile ?  20 : 0,
            marginBottom: 20,
            
        },
        documentList: {
            card: {
                overflow: 'auto',
                mb: 0
            },
            cardHeader: {
                textAlign: 'center',                
                backgroundColor: themeMuiV5.palette.primary.main,
                color: themeMuiV5.palette.primary.contrastText
            },
            cardContent: {
                overflow: 'auto',
                display: 'flex',
                padding: '10px 10px 24px !important',
            }
        }
    }

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoading(true);
            try {
                const sessionDays = sessions?.map((element) => (moment(new Date(element.startTime))).format('DD-MM-YYYY'));
                if(mounted) {
                    setCalendarSessionDays(sessionDays);
                    filterSessions(new Date());
                }
            } catch (error) {
                console.error('error mapping session days ', error);
            } finally {
                setLoading(false);
            }
            
        };
        fetchData();
        return () => {
            mounted = false;
        }
      }, [sessions]);
  
    return (<>
        { loading ?
            <Box style={{ display: 'flex', height: '100vh', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>    
            :
            <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={8} sx={stylesSidePanel.gridLeftItem}>
                    { hasSessions ?
                        <UpcomingSessionList 
                            sessions={filteredSessions}
                            courses={courses}
                            loading={loading}
                            onSessionClick={onSessionClick}
                            height={'100%'} 
                        />
                        :
                        <NewsDetail news={news}  height={'100%'} />
                    }
                </Grid>

                <Grid item xs={12} lg={4} sx={stylesSidePanel.gridRightItem}>
                    { (hasSessions && calendarSessionDays?.length > 0)  &&
                        <div style={stylesSidePanel.divCalendar}>
                            <Calendar 
                                locale={intl.locale}
                                tileClassName={({date}) => {
                                    if (calendarSessionDays.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                                        return 'highlight'
                                    }
                                }}
                                value={calendarDate}
                                onChange={(date) => {
                                    setCalendarDate(date);
                                    filterSessions(new Date(date))
                                }}
                            />
                        </div>
                    }
                    { userId && 
                        <Card sx={stylesSidePanel.documentList.card}>
                            <CardHeader title={intl.formatMessage({ id: "myDocuments.title" })}
                                sx={stylesSidePanel.documentList.cardHeader}
                            />
                            <CardContent sx={stylesSidePanel.documentList.cardContent}>
                                <UserDocumentList userId={userId}/>
                            </CardContent>
                        </Card>
                    }
                </Grid>
            </Grid>
        }
    </>);   
  };

export default SidePanel;
