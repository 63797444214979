import React, { useEffect, useState } from "react";

import { useIntl } from "gatsby-plugin-intl";

import repositoryService from "services/api/repositoryService";
import { handleError } from "utils/error/error-utils";
import CvcLoadingProgress from "ui/CvcLoadingProgress";
import StandardizeSelectDataPanel from "components/backoffice/repositories/tabs/StandardizeSelectDataPanel";
import StandardizeDestinationPanel from "components/backoffice/repositories/tabs/StandardizeDestinationPanel";


const StandardizeReferenceDatasTab = ({ 
    selectedReferenceTable, 
    setDisabledTables, 
    showErrorMessage, 
    showSuccessMessage 
}) => {

    const intl = useIntl();
    const [loadingDatas, setLoadingDatas] = useState(false);
    const [reloadDatas, setReloadDatas] = useState(true); 
    const [referenceDataList, setReferenceDataList] = useState([]);
    const [showStepOne, setShowStepOne] = useState(true);
    const [showStepTwo, setShowStepTwo] = useState(false);

    const [selectedData, setSelectedData] = useState([]);     
    const [disabledStandardizeBtn, setDisabledStandardizeBtn]= useState(true);
    const [disabledValidateBtn, setDisabledValidateBtn]= useState(true);    
    
    
    useEffect(() => {
        let mounted = true;
        if(mounted){
            setReloadDatas(true);
        }      
        return () => { mounted=false; };
    }, [selectedReferenceTable]);


    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoadingDatas(true);
            try {
                const result = await repositoryService.findReferenceDataByTable(selectedReferenceTable.value);
                if(mounted){                    
                    const referenceDataList = result.data.map(item => { 
                        item.label = item.label + ' ('+item.count +')';
                        return item;
                     });
                    setReferenceDataList(referenceDataList);
                    setSelectedData([]);                                                      
                }                                
            } catch (error) {
                handleError(
                    intl, error, 
                    intl.formatMessage({ id: 'backoffice.repositories.fetchValues.error'}), 
                    showErrorMessage
                );
            } finally {                
                setLoadingDatas(false);
                setReloadDatas(false);
            }
        };
        if(reloadDatas && !loadingDatas) fetchData();        
        return () => { mounted=false; };
    }, [reloadDatas]);


    const handleSelectedReferenceDataChange = (selectedItems) => {
        setSelectedData(selectedItems);
        if(selectedItems && selectedItems.length > 0){
            setDisabledStandardizeBtn(false);
        } else {
            setDisabledStandardizeBtn(true);
        }       
    }

    const handleStandardize = () => {
        goToStepTwo();
    }

    const handleReloadReferenceDataList = () => {
        setSelectedData([]);
        setReloadDatas(true);
    }

    const handleSelectDestination = (selectedOption) => {
        if(selectedOption && selectedOption.label){
            setDisabledValidateBtn(false);
        } else {
            setDisabledValidateBtn(true);
        }
    }

    const handleCancel = () => {
        goToStepOne();
    }
    
    
    const handleValidateStandardization = async (standardizeReferenceData) => {        
        try {
            const standardizeReferenceDataDto = {
                referenceValuesToTreat: selectedData.map(data => data.id),
                destinationValue: getDestinationObject(standardizeReferenceData.destination)
            }                                            
            await repositoryService.standardizeReferenceData(standardizeReferenceDataDto);
            goToStepOne();        
            showSuccessMessage(intl.formatMessage({ id: 'backoffice.repositories.standardisation.standardise.success'}));            
            setReloadDatas(true);     
        } catch (error) {
            handleError(
                intl, error, 
                intl.formatMessage({ id: 'backoffice.repositories.standardisation.standardise.error'}), 
                showErrorMessage
            );
        }
    }

    //Fix: handleInputUserOption n'étant pas appelé si on tape 'Entrée' au lieu de cliquer sur 'Add'  
    const getDestinationObject = (destination) => {
        const hasListCode = Object.hasOwn(destination, 'listCode');
        return hasListCode ? destination : {...destination, 'listCode' : selectedReferenceTable.value};
    }

    const goToStepOne = () => {
        setShowStepOne(true);
        setShowStepTwo(false);        
        setDisabledTables(false);        
    }

    const goToStepTwo = () => {        
        setShowStepOne(false);
        setShowStepTwo(true);
        setDisabledTables(true); 
        setDisabledValidateBtn(true); 
    }

    return (<>
        { loadingDatas &&  <CvcLoadingProgress /> }
        { !loadingDatas && showStepOne &&
            <StandardizeSelectDataPanel
                disabledStandardizeBtn={disabledStandardizeBtn} 
                referenceDataList={referenceDataList} 
                selectedData={selectedData}
                onChange={handleSelectedReferenceDataChange}                                 
                onReloadData={handleReloadReferenceDataList}
                onStandardize={handleStandardize}
            />
        } 
        { !loadingDatas && showStepTwo &&
            <StandardizeDestinationPanel 
                disabledValidateBtn={disabledValidateBtn}
                selectedReferenceTable={selectedReferenceTable}
                selectedData={selectedData}
                onSelectDestination={handleSelectDestination}                                                 
                onCancel={handleCancel}
                onValidate={handleValidateStandardization}
            />
        }        
    </>);
}

export default StandardizeReferenceDatasTab;